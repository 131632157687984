.App {
  text-align: center;
}

body {
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
    font-size: calc(var(--dynamic-width)*0.006);
    /* font-size: var(--dynamic-text-size-gauge); */
}






.Page1{
    /* height: 100vh;
    height: 1080px;
    width: 1920px; */
    /* width: calc((var(--dynamic-width) * var(--dynamic-Screenwidth)));
    display: flex;
    justify-content: center;
    align-items: center; this is for vertical centering */
    /* background-attachment: fixed; */
    height: var(--dynamic-height);
    width: var(--dynamic-width);
    background-size: cover;
    top:0px;
    position:relative;
    background-position: center; 

}

.Page2{
    height: var(--dynamic-height);
    width: var(--dynamic-width);
    background-size: cover;
    top:0px;
    position:relative;
    background-position: center; 
}


.FullScreenPage {
    height: var(--dynamic-height);
    width: var(--dynamic-width);
    background-size: cover;
    background-position: center; 
    position: relative;
    display: flex; /* Using flexbox to align children */
    justify-content: space-between; /* Push children to the edges */
    /* align-items: stretch; Stretch to fill vertically */
    /* padding: 0; Ensure no padding */
    /* margin: 0; Ensure no margin */
  
}

.HalfScreen{
    height: calc(var(--dynamic-height));
    /* width: calc((var(--dynamic-width) * var(--dynamic-Screenwidth))); */
    width: calc(var(--dynamic-width)*0.5);
    background-size: cover;
    background-position: center; 
    position:relative; /* position is important for child/ parent behavior/*
    /* margin: 0; Ensure no margin */
    /* padding: 0; Ensure no padding */

    /* flex-grow: 1;
    flex-shrink: 0; */
    /* flex-basis: calc((var(--dynamic-width) * var(--dynamic-Screenwidth)) / 2); */
    /* flex-basis: calc((var(--dynamic-width))/2); */
}

.HalfScreen2{
    height: calc(var(--dynamic-height));
    /* width: calc((var(--dynamic-width) * var(--dynamic-Screenwidth))); */
    width: calc(var(--dynamic-width)*0.5);
    background-size: cover;
    background-position: center; 
    position:relative;
}

.QuarterScreen{
    height: calc(var(--dynamic-height*0.46));
    width: calc(var(--dynamic-width)*0.25);
    background-size: cover;
    background-position: center; 
    position:relative;
  }

/* .PostList{
  
} */




.ENTROSYSTEM{
  /* height: calc(--dynamic-height / 2); */

  left:30px;
  top:25px;

  /* height: 70px; */
  /* width: calc((var(--dynamic-width)*var(--dynamic-Screenwidth))/ 2); */
  position:absolute;  
  /* font-size: xx-large;
  font-size: 80px;
  color: rgb(255, 255, 255); */
  z-index: 9999;  /* this ensures that the element is stacked on

  /* width: var(--dynamic-width); */
  /* width : calc((var(--dynamic-text-size) * 0.2));  */
  
  /* logo2 width and height*/
  width : 180px;
  height : 30px;
  
  /* logo3 width and height*/
  /* width : 180px;
  height : 36px; */

  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; this is for vertical centering */
}


.center-container3, .center-container4 {
    top:60px;
    position:relative;
}





.center-container0 {
    /* width: var(--dynamic-width); */
    width:100%;
    /* top:calc((var(--dynamic-width) * 0.015)); */
    /* height : 30px; */
    height : calc((var(--dynamic-width) * 0.015));
    top:20px;
    display: flex;
    flex-direction: row; /* Default, so optional */
    justify-content: center;
    align-items: center;
    position:relative;
}

.center-container1 {
    /* width: var(--dynamic-width); */
    width:100%;

    height : 30px;
    /* top:calc((var(--dynamic-width) * 0.015)); */
    top:20px;
    display: flex;
    flex-direction: row; /* Default, so optional */
    justify-content: center;
    align-items: center;
    position:relative;
}



.PRODUCT, .CHATBOT, .TECHNOLOGY, .ABOUT{
    /* font-size: x-large; */
    /* font-size: 30px; */
    font-size: 2.8rem;
    /* position:relative; */
    /* font-size: calc((var(--dynamic-text-size) * 0.028));  */
    color: rgb(255, 255, 255);
    margin: 0 50px;
}

.TryChat{
    /* left:30px; */
    /* top:calc((var(--dynamic-width) * 0.030)); */
    top:100px;
    position:relative;
    /* font-size: 50px; */
    font-size: 5rem;
    /* font-size: calc((var(--dynamic-text-size) * 0.05));  */
    color: rgb(255, 255, 255);
}
.EntrosytemProvide1{
    /* left:30px; */
    /* top:calc((var(--dynamic-width) * 0.030)); */
    top:40px;
    position:relative;
    /* font-size: 20px; */
    font-size: 2rem;
    /* font-size: calc((var(--dynamic-text-size) * 0.025));  */
    color: rgb(255, 255, 255);
}
.EntrosytemProvide2{
    /* left:30px; */
    /* top:calc((var(--dynamic-width) * 0.030)); */
    top:40px;
    position:relative;
    /* font-size: 20px; */
    font-size: 2rem;
    /* font-size: calc((var(--dynamic-text-size) * 0.025));  */
    color: rgb(255, 255, 255);
}



.AppletTitle{
    text-align: left;
    /* width: calc((var(--dynamic-width) * 0.4))!important; */
    width: 80%;
    left  : 5%;
    top   : 1%;
    /* left  : calc((var(--dynamic-width) * 0.05))!important; */
    /* left  : 20px; */
    /* top   : calc((var(--dynamic-width) * 0.05));  */
    position: relative;
    color: rgb(255, 255, 255);
    /* font-size: calc((var(--dynamic-text-size) * 0.05));  */
    /* font-size: 30px; */
    font-size: 4rem;
}

.AppletParagraph{
    text-align: left;
    /* width : calc((var(--dynamic-width) * 0.4))!important;
    left  : calc((var(--dynamic-width) * 0.05))!important; */
    width: 80%;
    left  : 5%;
    top   : 1%;

    /* top   : calc((var(--dynamic-width) * 0.05));  */
    position: relative;
    color: rgb(255, 255, 255);
    /* font-size: 18px; */
    /* font-size: 2.5rem; */
    font-size: 250%;
    /* font-size: calc((var(--dynamic-text-size) * 0.025));  */
}